@font-face {
   font-family: 'Pitch Sans';
   src: url('../fonts/pitch-sans-bold.woff2') format('woff2');
   font-weight: 700;
   font-style: normal;
}
	
@font-face {
   font-family: 'Geograph';
   src: url('../fonts/geograph-regular.woff2') format('woff2');
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'Internacional';
   src: url('../fonts/internacional-black.otf') format('opentype');
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: 'Internacional';
   src: url('../fonts/internacional-bold.otf') format('opentype');
   font-weight: 700; 
   font-style: normal;
}
  
@font-face { 
  font-family: 'Internacional';
  src: url('../fonts/internacional-semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Internacional';
  src: url('../fonts/internacional-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal; 
}  


// Custom Colors

$white:    #FFF !default;
$gray-100: #E7E8E8 !default;
$gray-200: #CFD2D2 !default;
$gray-300: #B7BBBB !default;
$gray-400: #9FA4A5 !default;
$gray-500: #878D8E !default;
$gray-600: #707777 !default;
$gray-700: #586061 !default;
$gray-800: #40494A !default;
$gray-900: #283334 !default;
$black:    #101C1D !default;

$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #D24628 !default;
$orange:  #E7A84F !default;
$yellow:  #F0C66F !default;
$green:   #54A74C !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$primary: $red;

$body-bg: #EDEDED;
$body-color: $black;
$dark: #101C1D;

// Custom Type

$font-size-base: 1.25rem;
$font-family-sans-serif: "Geograph" !important;
$font-family-base:  "Geograph" !important;

// Custom Card Style
$card-border-radius: .75rem;

// Custom Buttons

$btn-font-family: "Internacional" !important;
$btn-font-size-sm: 0.813rem !important;
$btn-padding-y-sm: 0.8rem !important;


$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 860px,
    xl: 960px,
    xxl: 1036px
  );


$carousel-indicator-width:           6px !important;
$carousel-indicator-height:          6px !important;

.small {
   font-family: 'Pitch Sans', sans-serif !important;
   font-size: 0.938rem !important;
   font-weight: bold;
   color: $gray-700;
}

.metric {
  font-family: "Internacional";
  font-size: 5.25rem;
  font-weight: bold;
}

.quote {
  font-family: "Geograph";
  font-size: 3.125rem;
  font-weight: normal;
}

.menu a, .menu p {
  font-family: "Internacional";
  color: $white;
  text-decoration: none;
}

.menu a:hover {
  
}



.wrapper {
  background-image: url(/images/background.svg), linear-gradient(
    to bottom,
    rgba(16, 28, 29,1) 0,
    rgba(16, 28, 29,1) 50px,
    rgba(16, 28, 29,0.0) 500px
    );
  background-position: 50% 0;
  background-blend-mode: soft-light;
  background-repeat: no-repeat;
  -webkit-background-size: cover, cover;
  background-size: cover, cover;

  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) { 
  .wrapper {
  background-image: url(/images/background.svg), linear-gradient(
  to bottom,
  rgba(16, 28, 29,1) 0,
  rgba(16, 28, 29,1) 600px,
  rgba(16, 28, 29,0.0) 600px
  );
}
   }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .wrapper {
  background-image: url(/images/background.svg), linear-gradient(
  to bottom,
  rgba(16, 28, 29,1) 0,
  rgba(16, 28, 29,1) 600px,
  rgba(16, 28, 29,0.0) 600px
  );
}
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .wrapper {
  background-image: url(/images/background.svg), linear-gradient(
  to bottom,
  rgba(16, 28, 29,1) 0,
  rgba(16, 28, 29,1) 760px,
  rgba(16, 28, 29,0.0) 760px
  );
}
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  .wrapper {
  background-image: url(/images/background.svg), linear-gradient(
  to bottom,
  rgba(16, 28, 29,1) 0,
  rgba(16, 28, 29,1) 920px,
  rgba(16, 28, 29,0.0) 920px
  );
}
  }

// XX-Large devices (larger desktops, 1400px and up)
// @media (min-width: 1400px) { 
//   .wrapper {
//   background-image: url(/images/background.svg), linear-gradient(
//   to bottom,
//   rgba(16, 28, 29,1) 0,
//   rgba(16, 28, 29,1) 80vh,
//   rgba(16, 28, 29,0.0) 80vh
//   );
// }
//   
//    }



@import "bootstrap";

body {
     font-family: 'Geograph', sans-serif !important;
   }
   
   h1 {
     font-family: 'Internacional', sans-serif !important;
     font-weight: 900;
   }
   
   h2 {
     font-family: 'Internacional', sans-serif !important;
       font-weight: 700;
   }
      
   h3 {
     font-family: 'Internacional', sans-serif !important;
       font-weight: 600;
   }
   
   h4 {
     font-family: 'Internacional', sans-serif !important;
      font-weight: 600;
   }
   
   h5 {
     font-family: 'Internacional', sans-serif !important;
      font-weight: 600;
   }
   
   h6 {
     font-family: 'Internacional', sans-serif !important;
      font-weight: 600;
   }

@media (min-width: 1200px) {
       .container{
           max-width: 1076px;
       }
   }